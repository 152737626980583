<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="업무함 관리">
      - 업무일지, 학습과정, 일일보고 등 업무사항을 작성해 주세요.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>

          <table class="table-row table-serach">
            <tbody>
            <tr>
              <th width="180">작성일 </th>
              <td>
                <carrot-date-picker v-model="bbs.sdate" class="w-110px float-left"></carrot-date-picker>
                <span class="float-left ml-10 mr-10">~</span>
                <carrot-date-picker v-model="bbs.edate" class="w-110px float-left"></carrot-date-picker>
              </td>
              <th width="180">작성자</th>
              <td width="500">
                <carrot-sector v-model="bbs.sector" class="float-left w-100px"></carrot-sector>
                <carrot-dept :idx_sector="bbs.sector" v-model="bbs.office" class="float-left w-100px  ml-5"></carrot-dept>
                <carrot-team :idx_sector="bbs.sector" :idx_office="bbs.office" v-model="bbs.team" class="float-left w-100px ml-5"></carrot-team>
                <carrot-staff :idx_sector="bbs.sector" :idx_office="bbs.office" :idx_team="bbs.team" v-model="bbs.staff" class="float-left w-100px ml-5"></carrot-staff>
              </td>
            </tr>
            <tr>
              <th width="180">제목</th>
              <td colspan="3">
                <input type="text" v-model.trim="bbs.title" class="w-100per">
              </td>
            </tr>
            </tbody>
          </table>
          <button @click="bbs.doSearch" class="btn-default float-right mt-10">검색</button>
          <div class="clear"></div>

          <div class="mt-40 mb-20">
            <select v-model="bbs.progress_state" @change="bbs.doInit" class="w-200px">
              <option value="">진행상태 전체</option>
              <option value="업무요청">업무요청</option>
              <option value="진행중">진행중</option>
              <option value="지연">지연</option>
              <option value="보류">보류</option>
              <option value="완료">완료</option>
            </select>
            <select v-model="bbs.filter" @change="bbs.doInit" class="w-200px ml-20">
              <option value="">전체 업무함</option>
              <option value="request">요청 업무함</option>
              <option value="receive">받은 업무함</option>
              <option value="share">공유 업무함</option>
            </select>
            <button @click="bbs.showAdd" class="btn-default float-right">업무작성</button>
            <div class="clear"></div>
          </div>

          <table class="table-col">
            <colgroup>
              <col width="70">
              <col width="110">
              <col width="*">
              <col width="180">
              <col width="170">
              <col width="130">
              <col width="100">
              <col width="100">
            </colgroup>
            <thead>
            <tr>
              <th>No</th>
              <th>작성일</th>
              <th>제목</th>
              <th>본부</th>
              <th>팀</th>
              <th>작성자</th>
              <th>중요도</th>
              <th>진행상태</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(irow, i) in bbs.list" :key="i">
              <td>{{ irow.num }}</td>
              <td>{{ irow.wdate }}</td>
              <td class="txt-left"><span @click="bbs.showView(irow.idx)" class="btn-view">{{ irow.title }}</span>
                <span class="txt-orange" v-if="irow.cmt_cnt!=0"> ({{irow.cmt_cnt}})</span>
              </td>
              <td>{{ irow.office }}</td>
              <td>{{ irow.team }}</td>
              <td>{{ irow.hq_ename }}({{ irow.hq_kname }})</td>
              <td>
                {{irow.priority}}
              </td>
              <td>
                {{irow.progress_state}}
              </td>
            </tr>
            <tr v-if="bbs.total==0">
              <td colspan="8">검색 결과가 없습니다. </td>
            </tr>
            </tbody>
          </table>
          <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotSector from '@/components/common/CarrotSector.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'


export default {
  layout:"BusinessManagement",
  components: {
    CarrotDatePicker,
    CarrotSector,
    CarrotDept,
    CarrotTeam,
    CarrotStaff
  },
  setup() {
    const router = useRouter();
    const toast = useToast()

    const bbs = reactive({
      is_mounted : false,
      ctype_list : [],
      stype_list : [],

      page    : 1,
      rows    : 50,
      sdate   : "",
      edate   : "",
      ctype   : "",

      sector  : 0,
      office  : 0,
      team  : 0,
      staff  : 0,
      manager : 0,
      title   : "",
      progress_state   : "",
      filter  : "",
      state   : "",

      list : [], total : 0,
      doInit : () => {
        bbs.page = 1;
        bbs.doSearch();
      },

      doSearch: () => {
        if( bbs.is_mounted == false ) return;

        let params = {
          page    : bbs.page,
          rows    : bbs.rows,
          sdate   : bbs.sdate,
          edate   : bbs.edate,
          ctype   : bbs.ctype,
          sector  : bbs.sector,
          office  : bbs.office,
          team  : bbs.team,
          staff  : bbs.staff,
          title   : bbs.title,
          progress_state   : bbs.progress_state,
          filter  : bbs.filter,
          state   : bbs.state
        };
        axios.get('/rest/task/getList', { params : params }).then((res) => {
          if( res.data.err == 0 ){
            bbs.list  = res.data.list;
            bbs.total = res.data.total;
            bbs.is_mounted = true;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }

          sessionStorage.removeItem('taskList');
        });
      },

      showView: (idx)=>{
        sessionStorage.setItem('taskList', JSON.stringify({
          page    : bbs.page,
          sdate   : bbs.sdate,
          edate   : bbs.edate,
          ctype   : bbs.ctype,
          sector  : bbs.sector,
          office  : bbs.office,
          team  : bbs.team,
          staff  : bbs.staff,
          title   : bbs.title,
          progress_state   : bbs.progress_state,
          filter  : bbs.filter,
          state   : bbs.state
        }));

        router.push({
          name   : 'TaskManagement-TaskView-idx',
          params : { idx:idx }
        });
      },

      showAdd: () => {
        sessionStorage.setItem('taskList', JSON.stringify({
          page    : bbs.page,
          sdate   : bbs.sdate,
          edate   : bbs.edate,
          ctype   : bbs.ctype,
          sector  : bbs.sector,
          office  : bbs.office,
          team  : bbs.team,
          staff  : bbs.staff,
          manager : bbs.manager,
          title   : bbs.title,
          progress_state   : bbs.progress_state,
          filter  : bbs.filter,
          state   : bbs.state
        }));

        router.push({
          name   : 'TaskManagement-taskAdd'
        });
      }
    });

    onMounted(() => {
      // Mounted

      let ss = sessionStorage.getItem("taskList");
      if( ss ){
        ss = JSON.parse(ss);
        bbs.page  = ss.page?ss.page:1;
        if( ss.sdate  ) bbs.sdate  = ss.sdate;
        if( ss.edate  ) bbs.edate  = ss.edate;
        bbs.office    = ss.office>0?ss.office:0;
        bbs.sector    = ss.sector>0?ss.sector:0;
        bbs.team  = ss.team>0?ss.team:0;
        bbs.staff  = ss.staff>0?ss.staff:0;
        bbs.title   = ss.title?ss.title:"";
        bbs.progress_state   = ss.progress_state?ss.progress_state:"";
        bbs.filter  = ss.filter?ss.filter:"";
        bbs.state   = ss.state?ss.state:"";
      }

      bbs.is_mounted = true;
      bbs.doSearch();
    });

    return {bbs};
  }
}
</script>

<style lang="scss" scoped>
</style>